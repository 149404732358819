.logo {
  margin-top: 1rem;
  margin-bottom:1rem;
  a {
    vertical-align: middle;
    line-height: 2.85;
  }
  img {
    width: 55%;
  }
  button {
    padding-left:0;
    padding-right:0;
    &:focus {
      outline: none;
    }
  }
}

@media (min-width: 992px) {
  .logo {
    img {
      min-width: 200px;
    }
  }
}

