nav {
  a {
    color:#333;
    text-decoration: none;
    min-width: 150px;
  }
  &.collapse {
    margin-bottom:1rem;
    a{
      min-width: 50%;
    }
  }
  .row div {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 3rem;
    transition: all 500ms;
    font-size: 1.2em;
    &:hover {
      background-color: #fff;
      cursor: pointer;
      color:#333;
      text-decoration: none;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }
}

@media(min-width: 768px) {
  nav.d-none {
    padding-left:0;
  }
}
